<template>
  <div class="mainform">
    <div class="form">
      <el-form ref="form" :model="formData" label-width="100px">
        <div class="col col8">
          <el-form-item label="姓名" prop="oldPassword">
            <span class="onlyText">{{formData.name}}</span>
          </el-form-item>
        </div>
        <div class="col col8">
          <el-form-item label="部门" prop="newPassword">
            <span class="onlyText">{{formData.deptName}}</span>
          </el-form-item>
        </div>
        <div class="col col8">
          <el-form-item label="手机" prop="verifyPassword">
            <span class="onlyText">{{formData.phone}}</span>
          </el-form-item>
        </div>
        <div class="col col8">
          <el-form-item label="邮箱" prop="verifyPassword">
            <span class="onlyText">{{formData.mail}}</span>
          </el-form-item>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PsnInfo',
  data () {
    return {
      formData: this.$store.state.loginInfo
    }
  }
}
</script>

<style scoped lang="scss">
  @import '@/assets/css/elForm.scss';
      .form {
        .el-form{
          :deep(.el-form-item){
                .el-form-item__content{
                    .el-input {
                        .el-input-group__append{
                            line-height: 32px;
                            padding: 0px 25px;
                            font-size: 13px;
                        }
                    }
                }
          }
        }
      }
</style>
